import React from 'react';
import styles from './GetInTouch.module.scss';
import ContactForm from './../ContactForm';
import SocialLinks from './../SocialLinks';
import * as endpoints from '../../global/endpoints';

class GetInTouch extends React.Component {
    componentDidMount() {
        document.title = `${endpoints.CONNECT_PAGE_TITLE_PARTIAL + endpoints.PAGE_TITLE_CONSTANT}`;
    }

    render() {
        return (
            <>
            <section className={styles.getInTouchContainer}>
                <div className={styles.contactRight}></div>
                <div className={styles.formComponent}>
                    <h1 className={styles.title}>Get in touch</h1>
                    <ContactForm />
                    <p className={styles.disclaimer}>This is just a way to reach out, and will not opt you into any mailing lists :)</p>
                    <div className={styles.socialLinks}>
                        <SocialLinks />
                    </div>
                </div>
            </section>
            </>
        )
    }    
}

export default GetInTouch;