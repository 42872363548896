import React from 'react';
import styles from './MailChimpForm.module.scss';




    class MailChimpForm extends React.Component {
        constructor(props) {
            super(props);
            this.state = {
                emailValue: '',
            };
        }

        render() {
            return (                
                <div className={styles.formContainer}>
                    <h2>Sign up for our monthly newsletter</h2>
                    <div id="mc_embed_signup" class="show">
                    <form
                          action="https://vanessarusu.us5.list-manage.com/subscribe/post?u=ac592e064105819e64ac3aced&amp;id=0ee52da4a1&amp;f_id=006c67e9f0"
                          method="post"
                          id="mc-embedded-subscribe-form"
                          name="mc-embedded-subscribe-form"
                          class="validate"
                          target="blank"
                        >
                            <div classname={styles.formLeft}>
                          <div id="mc_embed_signup_scroll">
                            
                            <div className={styles.form}>
                            <label>
                                <span className="sr-only">Email</span>                                
                              <input
                                type="email"
                                name="EMAIL"
                                class="required email"
                                id="mce-EMAIL"
                                required
                                value={this.state.emailValue} 
                                onChange={(e) => {this.setState({emailValue: e.target.value})}}
                              />
                              </label>
                              <span
                                id="mce-EMAIL-HELPERTEXT"
                                class="helper_text"
                              ></span>
                            </div>
                            <div hidden="true" className={styles.hiddenInput}>
                              <input type="hidden" name="tags" value="4139231" />
                            </div>
        
                            <div id="mce-responses" class="clear foot">
                              <div
                                class="response"
                                id="mce-error-response"
                                style={{ display: "none" }}
                              ></div>
                              <div
                                class="response"
                                id="mce-success-response"
                                style={{ display: "none" }}
                              ></div>
                            </div>
                          </div>
                            <div
                              style={{ position: "absolute", left: "-5000px;" }}
                              aria-hidden="true"
                              className={styles.hiddenInput}
                            >
                              <input
                                type="text"
                                name="b_ac592e064105819e64ac3aced_0ee52da4a1"
                                tabindex="-1"
                                value=""
                              />
                            </div>
                          </div>
        
                          <div className={styles.formRight}>
                            <input
                              type="submit"
                              value="Subscribe"
                              name="subscribe"
                              id="mc-embedded-subscribe"
                              className={styles.submitButton}
                            />
                          </div>
                        </form>
                    </div>
                    {/* <div id="submitSuccess" class="hide">
                        <p>Thanks, chat soon!</p>
                    </div> */}
                </div>
            )
        }

    
}

export default MailChimpForm;