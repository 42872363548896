import React from 'react';
import styles from './Marquee.module.scss';
// import squiggle from '../../img/squiggle.svg';
// import Flyout from '../Flyout';
// import { Link } from "react-router-dom";
import * as endpoints from '../../global/endpoints';

const Marquee = (props) => (
<>
<div className={styles.marquee}>
    <ul className={styles.marqueeInner} aria-hidden="true">
        <li>Brand Refreshes</li>
        <li>Marketing Collateral</li>
        <li>Website Design</li>
        <li>WordPress Design &amp; Development</li>
        <li>Shopify Development</li>
        <li>Custom UI Development</li>
        <li>Ongoing Website Support</li>
        <li>Startup Fundraising Design Support</li>

        <li>Brand Refreshes</li>
        <li>Marketing Collateral</li>
        <li>Website Design</li>
        <li>WordPress Design &amp; Development</li>
        <li>Shopify Development</li>
        <li>Custom UI Development</li>
        <li>Ongoing Website Support</li>
        <li>Startup Fundraising Design Support</li>
    </ul>
</div>
                </>
);

export default Marquee;