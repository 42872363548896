import React from 'react';
import styles from './Testimonial.module.scss';
import squiggle from '../../img/squiggle.svg';


class Testimonial extends React.Component {
    postId = 535;

    constructor(props) {
        super(props);
        this.state = {
            postData: false
        }
    }

    componentDidMount() {
        this.setState({postData: this.props.data});
    }

    render() {
        const hasData = this.props.data;

        if(hasData) {
            return (
                <section className={styles.testimonialPanel}>
                    <div className={styles.testimonialContentContainer}>
                        <h3 className={styles.sectionHeading}>Client Feedback</h3>
                        {this.props.data.content !== undefined ? (
                                <div className={styles.testimonialContent} dangerouslySetInnerHTML={{ __html: this.props.data.content.rendered }}></div>

                        ) : ''}
                        {this.props.data.post_content !== undefined ? (
                                <div className={styles.testimonialContent} dangerouslySetInnerHTML={{ __html: this.props.data.post_content }}></div>

                        ) : ''}

                        <img className={styles.squiggle} src={squiggle} alt="squiggle"></img>

                        {this.props.data.title !== undefined ? (
                            <p className={styles.author} dangerouslySetInnerHTML={{ __html: this.props.data.title.rendered }}></p>
                        ) : ''}
                        {this.props.data.post_title !== undefined ? (
                                <div className={styles.testimonialContent} dangerouslySetInnerHTML={{ __html: this.props.data.post_title }}></div>

                        ) : ''}
                        {/* <p className={styles.author} dangerouslySetInnerHTML={{ __html: this.props.data.title.rendered }}></p> */}
                    </div>
                    
                    
                </section>
            )
        }

        return false;
    }
}

export default Testimonial;