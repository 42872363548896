import React from 'react';
import styles from './Services.module.scss';
import squiggle from '../../img/squiggle.svg';
import squiggleWhite from '../../img/squiggle-white.svg';
import * as endpoints from '../../global/endpoints';
import { Link } from "react-router-dom";
import { Link as ScrollLink } from 'react-scroll'
import InstagramFeed from '../InstagramFeed';
import Testimonial from '../Testimonial';


class Services extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            pageCopy: false,
            postData: false,
            testimonials: [],
            coreValues: []
        }
    }

    componentDidMount() {
        document.title = `${endpoints.SERVICES_PAGE_TITLE_PARTIAL + endpoints.PAGE_TITLE_CONSTANT}`;

        Promise.all([
          fetch(endpoints.POSTS_BY_CAT(endpoints.SERVICES_POSTS)),
          fetch(endpoints.TESTIMONIALS()),
          fetch(endpoints.POSTS_BY_ID(endpoints.CORE_VALUES))
        ])
        .then(res => Promise.all(res.map(x => x.json())))
        .then((values) => {
            this.setState({
                pageCopy: values[0][0],
                postData: values[0].slice(1).reverse(),
                testimonials: values[1],
                coreValues: values[2]
            });
        });
    }

    render() {
        const hasData = this.state.postData && this.state.pageCopy;

        if (!hasData) {
            return null;
        }
        const postData = this.state.postData;
        const coreValuesHeader = this.state.coreValues.title.rendered;
        const coreValues = this.state.coreValues.acf.core_values;
        const randomTestimonial = this.state.testimonials[Math.floor(Math.random() * this.state.testimonials.length)];
        
        return (
            <>
            <section>
                <div className={styles.headerBackground}>
                    <div className={styles.headerContainer}>
                        <div className={styles.headerPanel}>
                            <h1>{this.state.pageCopy.title.rendered}</h1>
                            <img className={styles.squiggle} src={squiggle} alt="squiggle"></img>
                            <p dangerouslySetInnerHTML={{ __html: this.state.pageCopy.content.rendered}}></p>
                        </div>
                        <div className={styles.nav}>
                            <p>Jump to a service</p>
                        { 
                            Object.keys(postData).map((key) => {
                                return(
                                    <ScrollLink key={key} data-key={'0'+`${parseInt(key)+1}`} activeClass="active" className="test-1" to={`${"test-"+key}`} spy={true} smooth={true} duration={500} dangerouslySetInnerHTML={{ __html: postData[key].title.rendered }} style={{'--color': postData[key].acf.service_colour}}></ScrollLink>
                                )                        
                            })
                        }
                        </div>
                    </div>

                </div>
                

                <section className={styles.coreValues}>
                    <div className={styles.coreValuesContainer}>
                        <div className={styles.coreValuesHeader}>
                            <h2>{coreValuesHeader}</h2>
                        </div>
                        <div className={styles.coreValueItems}>
                        { 
                            Object.keys(coreValues).map((item) => {
                                return (
                                    <div key={item} id={coreValues[item].id}>
                                        <h3>{coreValues[item].post_title}</h3>
                                        <img className={styles.squiggle} src={squiggleWhite} alt="squiggle"></img>

                                        <p>{coreValues[item].post_content}</p>
                                    </div>
                                )                        
                            })
                        }

                        </div>
                    </div>
                </section>
                {/* <article className={styles.newServicesContainer}>
                    <div className={styles.container}>
                    <div className={styles.left}>
                        <span>01</span>
                        <h2>Web Design <br />&amp; Development</h2>
                        <p>Squarespace / WordPress / Shopify / Webflow</p>
                        <p>Your website is an extension of your brand and like your business, it’s purpose and goals can evolve over time. You may have a website that you need to grow with new features and pages, or you might need an entirely new website. </p>
                        <p>Typical website projects start at $5000</p>
                        <p>
                        <svg height="20" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><g id="Layer_2" data-name="Layer 2"><path d="m12 1a11 11 0 1 0 11 11 11.013 11.013 0 0 0 -11-11zm3.707 14.707a1 1 0 0 1 -1.414 0l-3-3a1 1 0 0 1 -.293-.707v-6a1 1 0 0 1 2 0v5.586l2.707 2.707a1 1 0 0 1 0 1.414z"/></g></svg>

                            Starts at $5000</p>
                        <p>
                        <svg height="20" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><g id="Layer_2" data-name="Layer 2"><path d="m12 1a11 11 0 1 0 11 11 11.013 11.013 0 0 0 -11-11zm3.707 14.707a1 1 0 0 1 -1.414 0l-3-3a1 1 0 0 1 -.293-.707v-6a1 1 0 0 1 2 0v5.586l2.707 2.707a1 1 0 0 1 0 1.414z"/></g></svg>
                            Typically 8 - 12 weeks</p>
                        <a className={styles.ctaButton}>start your project</a>
                    </div>
                        <div className={styles.right}>
                            <p>Typical projects include</p>
                            <ul>
                                <li>Website Strategy</li>
                                <li>Training</li>
                                <li>Post Launch Support</li>
                                <li>Website Design</li>
                            </ul>
                        </div>
                    </div>                
                </article> */}
                {/* <article className={styles.newServicesContainer}>
                    <div className={styles.container}>
                    <div className={styles.left}>
                        <h2>Web Design <br />&amp; Development</h2>
                        <p>Squarespace / WordPress / Shopify / Webflow</p>
                        <p>Your website is an extension of your brand and like your business, it’s purpose and goals can evolve over time. You may have a website that you need to grow with new features and pages, or you might need an entirely new website. </p>
                        <p>Typical website projects start at $5000</p>
                        <a className={styles.ctaButton}>start your project</a>
                    </div>
                        <div className={styles.right}>
                            <p>Typical projects include</p>
                            <ul>
                                <li>Website Strategy</li>
                                <li>Training</li>
                                <li>Post Launch Support</li>
                                <li>Website Design</li>
                            </ul>
                        </div>
                    </div>
                
                </article> */}

                {
                    Object.keys(postData).map((key) => {
                        const serviceDetails = postData[key].acf.service_includes_callouts;
                        return (
                        <>
                        
                            <section className={styles.newServicesContainer} name={`${"test-"+key}`} key={key}>
                                <div className={styles.container}>
                                    <div className={styles.left}>
                                        <span>0{parseInt(key)+1}</span>
                                        <h2>{postData[key].title.rendered}</h2>
                                        <div dangerouslySetInnerHTML={{ __html: postData[key].content.rendered }} className={styles.primaryContent} />

                                        { postData[key].acf.investment && postData[key].acf.timeline ? (
                                            <div className={styles.serviceMeta}>
                                                <div className={styles.investment}>
                                                <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="19" height="20" viewBox="0 0 45.958 45.958"><g><path d="M22.979,0C10.287,0,0,10.288,0,22.979s10.287,22.979,22.979,22.979s22.979-10.289,22.979-22.979S35.67,0,22.979,0z
                                            M24.37,33.215v2.66c0,0.415-0.323,0.717-0.739,0.717h-1.773c-0.416,0-0.751-0.302-0.751-0.717v-2.426
                                            c-1.632-0.074-3.278-0.422-4.524-0.896c-0.675-0.256-1.051-0.979-0.872-1.679L16,29.748c0.101-0.395,0.365-0.725,0.726-0.91
                                            c0.361-0.188,0.785-0.207,1.164-0.062c1.187,0.459,2.589,0.793,4.086,0.793c1.906,0,3.211-0.736,3.211-2.074
                                            c0-1.271-1.07-2.074-3.546-2.911c-3.579-1.204-6.03-2.876-6.03-6.121c0-2.943,2.083-5.251,5.644-5.954v-2.426
                                            c0-0.415,0.355-0.787,0.771-0.787h1.773c0.416,0,0.721,0.372,0.721,0.787v2.191c1.557,0.067,2.681,0.298,3.621,0.604
                                            c0.711,0.232,1.131,0.977,0.944,1.703l-0.254,1.008c-0.099,0.384-0.353,0.71-0.701,0.898s-0.759,0.222-1.135,0.093
                                            c-0.854-0.293-1.968-0.559-3.38-0.559c-2.174,0-2.877,0.937-2.877,1.874c0,1.104,1.171,1.806,4.014,2.877
                                            c3.98,1.405,5.579,3.245,5.579,6.254C30.33,30.003,28.227,32.547,24.37,33.215z"/></g></svg>

                                                    <p>{postData[key].acf.investment}</p>
                                                </div>
                                                <div className={styles.timeline}>
                                                <svg height="20" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><g id="Layer_2" data-name="Layer 2"><path d="m12 1a11 11 0 1 0 11 11 11.013 11.013 0 0 0 -11-11zm3.707 14.707a1 1 0 0 1 -1.414 0l-3-3a1 1 0 0 1 -.293-.707v-6a1 1 0 0 1 2 0v5.586l2.707 2.707a1 1 0 0 1 0 1.414z"/></g></svg>
                                                    <p>{postData[key].acf.timeline}</p>
                                                </div>
                                            </div>

                                        ) : ''}
                                        <Link to={endpoints.CONNECT_PAGE_LINK.link} className={`${styles.CTALink} ${styles.mobileHide}`}>Start your project</Link>
                                            
                                    </div>
                                    <div className={styles.right}>
                                        <div dangerouslySetInnerHTML={{ __html: postData[key].acf.right_side_list }} className={styles.primaryContent} />
                                        <div className={styles.serviceImage}>
                                                <img src={postData[key].acf.service_image_banner} />
            
                                            </div>
                                            <p className={styles.tagLine}>{postData[key].acf.supporting_callout}</p>
                                        </div>
                                        
                                        <Link to={endpoints.CONNECT_PAGE_LINK.link} className={`${styles.CTALink} ${styles.mobileShow}`}>Start your project</Link>
                                    </div>
                             
                </section>
                </>




                            // <div key={key} className={styles.serviceItemContainer} name={`${"test-"+key}`} style={{'--bg': postData[key].acf.service_colour}}>
                            //     <div className={`${styles.imageContainer}`}>
                            //         <img src={postData[key].acf.service_image_banner} alt={`${postData[key].title.rendered}`} />

                            //         </div>
                            //     <div className={styles.container}>
                            //         <img src={postData[key]._embedded['wp:featuredmedia']['0'].source_url} alt={postData[key]._embedded['wp:featuredmedia']['0'].alt_text} className={styles.introImg}/>
                            //         <div className={styles.serviceMainContent}>
                            //             <h3 className={styles.title} dangerouslySetInnerHTML={{ __html: postData[key].acf.supporting_callout }}></h3>
                                        
                            //             <div dangerouslySetInnerHTML={{ __html: postData[key].content.rendered }} className={styles.primaryContent} />
                            //             {/* <div className={styles.includes}>
                            //                 { serviceDetails && <h3>this service includes</h3>}
                            //                 { serviceDetails && 
                            //                     Object.keys(serviceDetails).map((key) => {
                            //                         return(
                            //                         <div key={key}>
                            //                             <h4>{serviceDetails[key].post_title}</h4>
                            //                             <p>{serviceDetails[key].post_content}</p>
                            //                         </div>
                            //                     )})
                                                
                                            
                            //                   }
                            //             </div> */}
                            //             <Link to={endpoints.CONNECT_PAGE_LINK.link} className={styles.CTALink}>Get in Touch</Link>
                            //         </div>
                            //     </div>
                            // </div>
                            )
                    })
                }
            </section>
            <Testimonial data={randomTestimonial} />
            <InstagramFeed />
            </>
        )
    }    
}

export default Services;